(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name events.vehicles.deposits.edit.controller:DepositsEditCtrl
   *
   * @description
   *
   */
  angular
    .module('events.vehicles.deposits.edit')
    .controller('DepositsEditCtrl', DepositsEditCtrl);

  function DepositsEditCtrl($state, $stateParams, $mdDialog, $filter, Restangular, event) {
    var vm = this,
      defaultSectionId = '';

    vm.submit = submit;
    vm.deleteDeposit = deleteDeposit;
    vm.showConfirmDelete = showConfirmDelete;
    vm.addAmount = addAmount;
    vm.removeAmount = removeAmount;
    vm.isBalanced = isBalanced;

    vm.event = event.data;
    vm.vehicle = null;
    vm.vehicles = null;
    vm.depositTypes = [];
    vm.sections = [];
    vm.paymentMethods = [];
    vm.depositSources = [];
    vm.deposit = {
      date: new Date(),
      drawer: '',
      adj_code: '',
      adj_comments: '',
      notes: '',
      payment_method_id: '',
      source_id: '',
      total: '',
      vehicle_id: '',
      deposit_entries: []
    };
    vm.created_at = '';
    vm.updated_at = '';

    vm.isEditing = $state.is('events.vehicles.deposits.edit');

    vm.errors = [];

    if (vm.isEditing) {

      // Get deposit
      Restangular.one('deposits', $stateParams.depositId).get({inc: 'DepositEntries'}).then(function (response) {

        // Cents to dollars
        response.data.total = $filter('dollars')(response.data.total);
        for (var i = 0; i < response.data.deposit_entries.length; i++) {
          response.data.deposit_entries[i].amount = $filter('dollars')(response.data.deposit_entries[i].amount);
        }

        vm.deposit = response.data;
        vm.date = new Date(vm.deposit.date);
        vm.created_at = moment(vm.deposit.created_at).format('Do MMM YYYY');
        vm.updated_at = moment(vm.deposit.updated_at).format('Do MMM YYYY');
      });
    } else {
      vm.date = new Date();
      vm.deposit.deposit_entries.push({});
    }

    // Get vehicles
    Restangular.one('events', vm.event.id).all('vehicles').getList().then(function (response) {
      vm.vehicles = response;

      // Set current vehicle
      if ($stateParams.vehicleId) {
        for (var i = 0; i < response.length; i++) {
          if ($stateParams.vehicleId === response[i].id) {
            vm.vehicle = response[i];
            vm.deposit.vehicle_id = response[i].id;
            if (!vm.isEditing) {
              vm.deposit.deposit_entries[0].section_id = response[i].section_id;
            }
            break;
          }
        }
      }
    });

    // Get deposit types
    Restangular.all('deposit-types').withHttpConfig({cache: true}).getList().then(function (response) {
      vm.depositTypes = response;
    });

    // Get sections
    Restangular.all('sections').withHttpConfig({cache: true}).getList().then(function (response) {
      vm.sections = response;
    });

    // Get payment methods
    Restangular.all('payment-methods').getList().then(function (response) {
      vm.paymentMethods = response;
    });

    // Get deposit sources
    Restangular.all('deposit-sources').getList().then(function (response) {
      vm.depositSources = response;

      // Set the default source
      if (!vm.isEditing) {
        for (var i = 0; i < vm.depositSources.length; i++) {
          if (vm.depositSources[i].is_default) {
            vm.deposit.source_id = vm.depositSources[i].id;
            break;
          }
        }
      }
    });

    function submit() {

      if (!isBalanced()) {
        $mdDialog.show(
          $mdDialog.alert()
            .title('Total amount mismatch')
            .textContent('The sum of the deposit entries ($' + total() + ') does not match the total ($' + vm.deposit.total + '). Please fix this before continuing.')
            .ariaLabel('Total amount mismatch')
            .clickOutsideToClose(true)
            .ok('OK')
        );
        return;
      }

      var data = angular.copy(vm.deposit);
      data.total = $filter('cents')(data.total);
      data.date = vm.date;
      for (var i = 0; i < data.deposit_entries.length; i++) {
        data.deposit_entries[i].amount = $filter('cents')(data.deposit_entries[i].amount);
      }

      if (vm.isEditing) {
        Restangular.all('deposits').customPUT(data, vm.deposit.id).then(submitSuccess, submitFailure);
      } else {
        Restangular.all('deposits').post(data).then(submitSuccess, submitFailure);
      }
    }

    function submitSuccess(response) {
      $state.go('events.vehicles.deposits.list', {eventId: vm.event.id, vehicleId: vm.deposit.vehicle_id});
    }

    function submitFailure(response) {
      console.log('deposit submit failed', response.data.errors);
      vm.errors = response.data.errors;
    }

    function deleteDeposit() {
      Restangular.one('deposits', vm.deposit.id).remove().then(deleteSuccess, deleteFailure);
    }

    function deleteSuccess() {
      $state.go('events.vehicles.deposits.list');
    }

    function deleteFailure(response) {
      console.log('failed delete', response);
      $state.go('events.vehicles.deposits.list');
    }

    function showConfirmDelete(ev) {
      var confirm = $mdDialog.confirm()
        .title('Delete Deposit')
        .textContent('Do you really want to delete this deposit?')
        .ariaLabel('Confirm delete deposit')
        .targetEvent(ev)
        .ok('Delete')
        .cancel('Cancel');
      $mdDialog.show(confirm).then(function () {
        deleteDeposit();
      });
    }

    function addAmount() {

      var amount = {
        amount: vm.deposit.total - total(),
        deposit_type_id: ''
      };

      // Set default value for section based on vehicle section
      if (vm.vehicle) {
        amount.section_id = vm.vehicle.section_id;
      }

      vm.deposit.deposit_entries.push(amount);
    }

    function removeAmount(i) {
      vm.deposit.deposit_entries.splice(i, 1);
    }

    function total() {
      var t = 0;
      for (var i = 0; i < vm.deposit.deposit_entries.length; i++) {
        if (vm.deposit.deposit_entries[i].amount) {
          t += vm.deposit.deposit_entries[i].amount;
        }
      }
      return t
    }

    function isBalanced() {
      return total() === vm.deposit.total;
    }
  }

}());
